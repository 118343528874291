import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {
  signInWithPopup,
  User,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut
} from "firebase/auth";
import {getAuthInLanguage} from "./get-auth";

interface AuthContextProps {
  user: User | null;
  logOut?: () => Promise<void>;
  googleSignInWithPopup?: () => Promise<User>;
}

const userAuthContext = createContext<AuthContextProps>({user: null});

export function UserAuthContextProvider({children}: { children: ReactNode | ReactNode[] }) {
  const [user, setUser] = useState<User | null>(null);

  const logOut = useCallback(() => signOut(getAuthInLanguage()), []);

  const googleSignInWithPopup = useCallback(() => {
    const provider = new GoogleAuthProvider();
    const auth = getAuthInLanguage();
    return signInWithPopup(auth, provider)
      .then(({user}) => {
        setUser(user);
        return user;
      });
  }, [setUser ]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuthInLanguage(), (currentUser) => {
      console.log("Auth", currentUser);
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{user, googleSignInWithPopup, logOut}}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
