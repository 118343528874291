import {doc, getDoc, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {BookingRecord} from "../models/booking-request";

/** Get a booking */
export async function getBooking(bookingId: string) {
  const db = getFirestore(firebaseApp);
  const bookingDocRef = doc(db, 'bookings', bookingId);
  const bookingDoc = await getDoc(bookingDocRef);

  return {
    ...(await bookingDoc.data()),
    id: bookingDoc.id
  } as BookingRecord;
}
