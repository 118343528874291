import {deleteField, doc, getFirestore, runTransaction} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {Slot} from "../models/slot";

// Cancel a slot reservation and free the slot, deleting the booking document
export async function cancelSlotBooking(slotId: string) {
  const db = getFirestore(firebaseApp);

  const slotDocRef = doc(db, 'slots', slotId);

  await runTransaction(db, async (transaction) => {
    const slotDoc = await transaction.get(slotDocRef);
    if (!slotDoc.exists()) {
      throw new Error(`Slot ${slotId} does not exist!`);
    }

    const slot = slotDoc.data() as Slot;
    if (!slot.bookingId) {
      throw new Error(`Slot ${slotId} is not booked`);
    }

    const bookingDocRef = doc(db, 'bookings', slot.bookingId);

    transaction.delete(bookingDocRef);
    transaction.update(slotDocRef, 'bookingId', deleteField());
  });
}
