import React, {ReactNode, useState} from "react";
import {DIALOG_CHOICES, useDialogContext} from "../../shared/dialog/dialog-provider";
import {cancelSlotBooking} from "../../shared/data-services/cancel-slot-booking";
import {slotName} from "../../shared/models/slot-utils";
import {Slot} from "../../shared/models/slot";
import Button from "@mui/material/Button";
import EventBusyIcon from '@mui/icons-material/EventBusy';

interface CancelBookingButtonProps {
  buttonClassName?: string;
  children?: ReactNode|ReactNode[];
  onSuccess?: () => void;
  slot: Slot;
}

export function CancelBookingButton({buttonClassName, children = 'free up', slot, onSuccess}: CancelBookingButtonProps) {
  const {askModalQuestion, showSnackbarMessage} = useDialogContext();
  const [isCancelling, setIsCancelling] = useState(false);

  async function confirmAndCancel() {
    const slotNameFormatted = slotName(slot);
    setIsCancelling(true);
    const choice = await askModalQuestion({
      heading: <>Free up {slotNameFormatted}?</>,
      prompt: 'Are you sure you want to cancel the booking of this slot?'
    });

    if (choice === DIALOG_CHOICES.NO) {
      setIsCancelling(false);
      return;
    }

    try {
      await cancelSlotBooking(slot.id);
      showSnackbarMessage(<>Slot {slotNameFormatted} has been set free</>);
      onSuccess?.();
      setIsCancelling(false);
    } catch (error) {
      console.error(error);
      const errorMessage = (error as Error).message;
      showSnackbarMessage(<span className="error">Failed to free the slot. {errorMessage}</span>);
      setIsCancelling(false);
    }
  }

  return (
    <Button
      disabled={isCancelling}
      startIcon={<EventBusyIcon/>}
      className={`${buttonClassName} ${isCancelling ? 'pulsating' : ''}`}
      onClick={confirmAndCancel}>
      {children}
    </Button>
  );
}
