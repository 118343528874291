import {useState, useEffect, useCallback} from 'react';

/** Custom hook for managing localStorage with a generic type */
function useLocalStorage<T=string>(key: string, initialValue: T) {
  // Initialize the state with the value from localStorage or the initial value
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window?.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) as T : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  // Function to update localStorage when the state changes
  const setValue = useCallback((value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function, so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window?.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  }, [storedValue, key]);

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const item = window?.localStorage.getItem(key);
        setStoredValue(item ? JSON.parse(item) as T : initialValue);
      } catch (error) {
        console.log(error);
      }
    };

    // Setup event listener for storage changes
    window?.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => window?.removeEventListener('storage', handleStorageChange);
  }, [key, initialValue]);

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
