// DaySlots.tsx
import React from 'react';

import styles from './DaySlots.module.scss';
import {Slot} from "../shared/models/slot";
import {Chip, Icon} from "@mui/material";

interface DaySlotsProps {
  slots: Slot[];
  selectedHour?: number;
  onSelect: (slot: Slot) => void;
}

export function DaySlots({slots, selectedHour, onSelect}: DaySlotsProps) {
  return (
    <div className={styles.DaySlots}>
      {
        slots.map((daySlot) => {
          const isSelected = daySlot.hour === selectedHour;
          return (<Chip
            key={daySlot.id}
            icon={<Icon>{isSelected ? 'task_alt' : 'schedule'}</Icon>}
            onClick={() => onSelect(daySlot)}
            label={`${daySlot.hour}:00`}
            variant={isSelected ? 'filled' : "outlined"}
            color={isSelected ? 'primary' : 'default'}
          />);
        })
      }
    </div>
  );
}
