import React, {useCallback, useState} from 'react';

import Button from "@mui/material/Button";
import {Drawer} from "@mui/material";
import {useTranslation} from "react-i18next";
import useLocalStorage from "../shared/data-services/use-local-storage";

export function CookiesDisclaimer() {
  const [shouldHideCookies, storeHideCookies] = useLocalStorage('drd-hide-cookies', false);
  const [isCookieDisclaimerOpen, setCookieDisclaimerOpen] = useState(!shouldHideCookies);
  const {t} = useTranslation();

  const hideCookiesAndRemember = useCallback(() => {
    setCookieDisclaimerOpen(false);
    storeHideCookies(() => true);
  }, [setCookieDisclaimerOpen, storeHideCookies]);


  return (
    <Drawer
      open={isCookieDisclaimerOpen}
      anchor="bottom"
    >
      <p>{t('cookies.disclaimer')}</p>
      <p>
        <Button onClick={hideCookiesAndRemember}>OK</Button>
      </p>
    </Drawer>
  );
}
