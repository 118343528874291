import {doc, getFirestore, runTransaction} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";

export async function createSlot(day: string, hour: number) {
  const db = getFirestore(firebaseApp);
  const slotId = `slot-${day}-${hour}`;
  const slotDocRef = doc(db, 'slots', slotId);

  await runTransaction(db, async (transaction) => {
    const slotDoc = await transaction.get(slotDocRef);

    if (slotDoc.exists()) {
      throw new Error(`Slot with Id ${slotId} already exists!`);
    }

    transaction.set(slotDocRef, {day, hour});
  });
}
