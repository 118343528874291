import {createTheme, PaletteOptions} from '@mui/material/styles';
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import {forwardRef} from "react";
import {LinkProps as MuiLinkProps} from '@mui/material/Link';

// Define a type for the props expected by LinkBehavior
type LinkBehaviorProps = Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }

const LinkBehavior = forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
  (props, ref) => {
    const {href, ...other} = props;
    return <RouterLink ref={ref} to={href} {...other}/>;
  });

const secondaryColor: PaletteOptions['secondary'] = {
  main: '#00ccd0',
  // TODO: make all the options in style
  // light: '#22dde0',
  // dark: '#008080',
  // contrastText: '#000',
};

export const kpTheme = createTheme({
  palette: {
    secondary: secondaryColor,
  },
  components: {
    // MUI v5 uses the MuiTextField key for the TextField component
    MuiButton: {
      defaultProps: {
        variant: "contained"
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as MuiLinkProps,
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
  },
});
