import {ReactNode} from "react";
import './Snackbars.css';

interface SnackbarsProps {
  messages: ReactNode[];
}

export default function Snackbars({messages}: SnackbarsProps) {
  return (
    <div className="snackbars">
      {
        messages.map((message) =>
          <div key={JSON.stringify(message)}>
            {message}
          </div>
        )
      }
    </div>
  )
}
