// LanguagePicker.tsx
import React, {useRef, useState} from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {LanguageTwoTone} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {SITE_LANGUAGES, STORAGE_KEY_LANG} from "../i18n-init";
import useLocalStorage from "../../shared/data-services/use-local-storage";
import styles from './LanguagePicker.module.scss';
import {Chip} from "@mui/material";

export function LanguagePicker() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const {i18n, t} = useTranslation();
  const [, storeLanguage] = useLocalStorage(STORAGE_KEY_LANG, 'en');

  function switchToLanguage(lang: string) {
    setOpen(false);
    i18n.changeLanguage(lang)
      .then(() => storeLanguage(lang));
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Chip
        className={styles.component}
        ref={anchorRef}
        icon={<LanguageTwoTone/>}
        onClick={handleToggle}
        label={<span className="language-chip">
          <span className="lang-full">{t(`languages.${i18n.language}`)}</span>
          <span className="lang-short">{i18n.language}</span>
        </span>}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="menu"
        aria-label={t('languageSwitch.ariaLabel')}
        variant="outlined"/>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {SITE_LANGUAGES.map((option) => (
                    <MenuItem
                      key={option}
                      disabled={option === i18n.language}
                      selected={option === i18n.language}
                      onClick={() => switchToLanguage(option)}
                    >
                      {t(`languages.${option}`)} ({t(`languages.${option}`, {lng: option})})
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
