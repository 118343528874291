export function objectToText(obj: any, indent = '  ', depth = 0): string {
  if (typeof obj !== 'object' || obj === null || obj instanceof Date) {
    return `${obj}`;
  }

  const deepIndent = indent.repeat(depth);
  let result = ``;

  // Iterate through the object's keys to build the string representation
  if (Array.isArray(obj)) {

    result += `[\n`;
    for (const item of obj) {
        result += `${deepIndent}${indent}${objectToText(item, indent, depth + 1)}\n`;
    }
    result += `${deepIndent}]`;

  } else if (obj instanceof Object && !(obj instanceof Function) ) {

    result += `{\n`;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        result += `${deepIndent}${indent}${key}: ${objectToText(value, indent, depth + 1)}\n`;
      }
    }
    result += `${deepIndent}}`;

  }
  return result;
}
