// HomeTiles.tsx
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './HomeTiles.module.scss';
import {Card, CardContent, Fab, Icon, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import {getHomeTiles} from "../shared/data-services/get-home-tiles";
import {HomeTile, HomeTilesMap} from "../shared/models/home-tiles/home-tile";
import {languageResources, SITE_LANGUAGES} from "../i18n/i18n-init";
import i18n from "i18next";

const homeTilesCache: HomeTilesMap = {};

for (const lang of SITE_LANGUAGES) {
  homeTilesCache[lang] = languageResources[lang].translation.home.tiles
    .map((tile: HomeTile) => ({
      ...tile,
      route: languageResources[lang].translation.home.serviceCollection + '/' + tile.id
    }));
}

const currentLanguage = i18n.language;
const savedHomeTiles = window?.localStorage.getItem(`mp-home-tiles-${currentLanguage}`);

try {
  if (savedHomeTiles) {
    homeTilesCache[currentLanguage] = JSON.parse(savedHomeTiles);
  }
} catch (err) {
  console.error(`Error parsing saved home tiles for language "${currentLanguage}":`, err);
}

export function HomeTiles() {
  const {t, i18n: {language}} = useTranslation();
  const [homeTiles, setHomeTiles] = useState<HomeTile[]>(homeTilesCache[language] || []);
  const serviceCollection = useMemo<string>(() => t('home.serviceCollection'), [t]);

  const loadHomeTiles = useCallback(async () => {
    setHomeTiles(homeTilesCache[language]);

    try {
      const newHomeTiles = (await getHomeTiles(serviceCollection))
        .map((contentDoc) => ({
          ...contentDoc,
          route: `${serviceCollection}/${contentDoc.id}`
        } as HomeTile));
      setHomeTiles(newHomeTiles);
      homeTilesCache[language] = newHomeTiles;
      window?.localStorage.setItem(`mp-home-tiles-${language}`, JSON.stringify(newHomeTiles));
    } catch (err) {
      console.error(err);
    }
  }, [serviceCollection, language]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    loadHomeTiles();
  }, [loadHomeTiles]);

  return (
    <div className={styles.component}>
      {homeTiles.map((tile) => (<Card key={tile.heading}>
        <CardContent>
          <article>
            <p>
              <Fab size="large" disabled>
                <Icon fontSize="large">{tile.iconKey}</Icon>
              </Fab>
            </p>
            <Typography variant="h5" gutterBottom>
              {tile.heading}
            </Typography>

            <div>
              {tile.teaser}
            </div>

            {
              tile.route &&
                <Link href={tile.route} color="inherit" sx={{color: '#999'}}>{t('general.readMore')}</Link>
            }
          </article>
        </CardContent>
      </Card>))}
    </div>
  );
}
