import {useUserAuth} from "./firebase/auth/firebase-auth-provider";
import {Route, Routes} from "react-router-dom";
import BookingForm from "./booking/BookingForm";
import {AdminConsole} from "./admin/AdminConsole";
import React from "react";
import {Unauthorized} from "./error-pages/401";
import {Booking} from "./admin/bookings/Booking";
import {BookingList} from "./admin/bookings/BookingList";
import {ConsultingDayList} from "./admin/consulting-days/ConsultingDayList";
import {SITE_LANGUAGES} from "./i18n/i18n-init";
import {ContentPage} from "./shared/editable/ContentPage";
import {Home} from "./home/Home";

export function Routing() {
  const {user} = useUserAuth();
  return <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/book/" element={<BookingForm/>}/>
    {SITE_LANGUAGES.map((language) =>
      <Route path={`/${language}/*`} element={<ContentPage />} key={language}/> )}
    <Route path="/admin" element={user ? <AdminConsole/> : <Unauthorized/>}/>
    <Route path="/admin/bookings" element={user ? <BookingList/> : <Unauthorized/>}/>
    <Route path="/admin/days" element={user ? <ConsultingDayList/> : <Unauthorized/>}/>
    <Route path="/admin/bookings/:bookingId" element={user ? <Booking/> : <Unauthorized/>}/>
  </Routes>
}
