import {doc, getFirestore, runTransaction} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {Slot} from "../models/slot";

// Delete a slot which needs to be not booked
export async function deleteFreeSlot(slotId: string) {
  const db = getFirestore(firebaseApp);

  const slotDocRef = doc(db, 'slots', slotId);

  await runTransaction(db, async (transaction) => {
    const slotDoc = await transaction.get(slotDocRef);
    if (!slotDoc.exists()) {
      throw new Error(`Slot ${slotId} does not exist!`);
    }

    const slot = slotDoc.data() as Slot;
    if (slot.bookingId) {
      throw new Error(`Slot ${slotId} is booked. Cancel the booking before deleting.`);
    }

    transaction.delete(slotDocRef);
  });
}
