import {collection, getDocs, getFirestore, orderBy} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {currentDate} from "../functions/current-date";
import {query, where} from "@firebase/firestore";
import {BookingRecord} from "../models/booking-request";

// Get a list of upcoming bookings from your database sorted by day and hour
export async function getBookings() {
  const db = getFirestore(firebaseApp);
  const bookingsCol = collection(db, 'bookings');
  try {
    const bookingsSnapshot = await getDocs(
      query(bookingsCol,
        where('slot.day', '>=', currentDate()),
        orderBy('slot.day'),
        orderBy('slot.hour')
      )
    );
    
    return bookingsSnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as BookingRecord[];
  } catch
    (err) {
    console.error(err);
    return [] as BookingRecord[];
  }
}
