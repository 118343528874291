import {getFirestore, collection, getDocs, orderBy} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {Slot} from "../models/slot";
import {currentDate} from "../functions/current-date";
import {query, where} from "@firebase/firestore";

// Get a list of slots from your database sorted by day and hour
export async function getSlots() {
  const db = getFirestore(firebaseApp);
  const slotsCol = collection(db, 'slots');
  try {
    const slotsSnapshot = await getDocs(
      query(slotsCol,
        where('day', '>=', currentDate()),
        orderBy('day'),
        orderBy('hour')
      )
    );

    return slotsSnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Slot[];
  } catch
    (err) {
    console.error(err);
    return [] as Slot[];
  }
}
