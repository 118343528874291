import {PayloadStates} from "./payload-states";

export function bookingStateToButtonText(payloadState: PayloadStates): string {
  switch (payloadState) {
    case PayloadStates.SAVING:
      return 'booking.form.button.booking';
    case PayloadStates.SAVED:
      return 'booking.form.button.booked';
    default:
      return 'booking.form.button.book';
  }
}
