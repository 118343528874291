// Home.tsx
// banner https://www.shutterstock.com/ru/image-photo/portrait-investment-advisor-businesswoman-sitting-office-428774194
import React from 'react';

import styles from './Home.module.scss';
import {HomeTiles} from "./HomeTiles";
import {BasicLayout} from "../shared/layout/BasicLayout";

export function Home() {

    return (
        <div className={styles.component}>
            <BasicLayout hasCtasOnMobile>
                <HomeTiles/>
            </BasicLayout>
        </div>
    );
}
