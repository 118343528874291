import {ConsultingDay} from "../../shared/models/consulting-day";
import React, {useEffect, useState} from "react";
import {getSlots} from "../../shared/data-services/get-slots";
import {slotNameString, slotsToConsultingDays} from "../../shared/models/slot-utils";
import styles from './ConsultingDayList.module.scss'
import {Link} from "react-router-dom";
import {useDialogContext} from "../../shared/dialog/dialog-provider";
import {createSlot} from "../../shared/data-services/create-slot";
import {ConsultingDaySlots} from "./ConsultingDaySlots";
import {deleteFreeSlot} from "../../shared/data-services/delete-slot";
import {Slot} from "../../shared/models/slot";
import {AddSlot} from "../slots/AddSlot";
import {Card, CardContent, CardHeader} from "@mui/material";

let persistentConsultingDays: ConsultingDay[] = [];

export function ConsultingDayList() {
  const [consultingDays, setConsultingDays] = useState(persistentConsultingDays);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');
  const {showSnackbarMessage} = useDialogContext();
  const [slotsBeingChanged, setSlotsBeingChanged] = useState<Slot[]>([]);

  function startChangingSlot(slot: Slot) {
    setSlotsBeingChanged(slotsBeingChanged => [...slotsBeingChanged, slot]);
  }

  function finishChangingSlot(slot: Slot) {
    setSlotsBeingChanged(slotsBeingChanged => slotsBeingChanged
      .filter((s) => s !== slot));
    fetchSlots();
  }

  async function addSlot(slot: Slot) {
    startChangingSlot(slot);
    try {
      await createSlot(slot.day, slot.hour);
      finishChangingSlot(slot);
      showSnackbarMessage(`Created a free slot ${slotNameString(slot)}`);
    } catch (error) {
      console.error(error);
      const errorMessage = (error as Error).message;
      showSnackbarMessage(<span className="error">Failed to create a slot. {errorMessage}</span>);
      finishChangingSlot(slot);
    }
  }

  async function deleteSlot(slot: Slot) {
    startChangingSlot(slot);

    try {
      await deleteFreeSlot(slot.id);
      showSnackbarMessage(<>Slot {slotNameString(slot)} has been deleted</>);
      finishChangingSlot(slot);
    } catch (error) {
      console.error(error);
      const errorMessage = (error as Error).message;
      showSnackbarMessage(<span className="error">Failed to delete the slot. {errorMessage}</span>);
      finishChangingSlot(slot);
    }
  }

  function fetchSlots() {
    setPending(true);
    getSlots()
      .then(slotsToConsultingDays)
      .then((days) => {
        persistentConsultingDays = days;
        setConsultingDays(days);
        setPending(false);
      })
      .catch((error) => {
        console.error('Error fetching slots:', error);
        setError('Error fetching slots: ' + JSON.stringify(error))
        setPending(false);
      })
  }

  useEffect(() => fetchSlots(), []);

  const listClassName = `${pending ? 'pulsating' : ''} ${consultingDays.length > 0 ? '' : 'empty'}`

  return <div className={styles.component}>
    <h2>Consulting days</h2>
    <p>See also: <Link to='/admin/bookings'>Bookings</Link></p>

    <AddSlot onAddSlot={fetchSlots}/>
    {error && <p className="error">{error}</p>}

    <div className={listClassName}>
      {
        consultingDays.map((consultingDay) =>
          <Card key={consultingDay.day} sx={{marginBottom: '1rem'}}>
            <CardHeader title={consultingDay.day} sx={{background: 'BlanchedAlmond'}}/>
            <CardContent>
              <ConsultingDaySlots
                slotsBeingChanged={slotsBeingChanged}
                slots={consultingDay.slots}
                day={consultingDay.day}
                onAddSlot={(slot) => addSlot(slot)}
                onDeleteSlot={deleteSlot}
              />
            </CardContent>
          </Card>)
      }
    </div>
  </div>
}
