import React, {useCallback, useEffect, useState} from "react";
import {useUserAuth} from "../firebase/auth/firebase-auth-provider";
import styles from './TopNavigator.module.scss';
import {useTranslation} from "react-i18next";
import {LanguagePicker} from "../i18n/switch/LanguagePicker";
import Link from "@mui/material/Link";
import {BlueCrossStar} from "../shared/imagery/BlueCrossStar";
import {Drawer, Icon, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {DrawerMenu} from "./DrawerMenu";
import {useNavigate} from "react-router-dom";

export function TopNavigator() {
  const {t, i18n} = useTranslation();
  const {user} = useUserAuth();
  const isSignedIn = Boolean(user);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!isDrawerOpen);
  }, [setDrawerOpen, isDrawerOpen]);

  useEffect(() => {
    if (isWide) {
      closeDrawer();
    }
  }, [isWide, closeDrawer]);

  return (<>
      <header className={styles.component}>
        <nav>
          <Link href="/"><BlueCrossStar size="2rem"/></Link>
          {
            isWide &&
              <>
                  <Link href="/book" color="primary">{t('booking.linkLabel')}</Link>
                  <Link href={t('about.route')}>{t('about.title')}</Link>
                  <Link href={`/${i18n.language}/contact`}>{t('topMenu.contact')}</Link>
                {isSignedIn && <Link href="/admin">Manage</Link>}
              </>
          }

          <LanguagePicker/>

          {
            isWide ||
              <IconButton onClick={toggleDrawer}>
                  <Icon>menu</Icon>
              </IconButton>
          }
        </nav>
      </header>

      <Drawer open={isDrawerOpen} onClose={closeDrawer}>
        <DrawerMenu
          canManage={Boolean(user)}
          onItemSelect={(route) => {
            closeDrawer();
            navigate(route);
          }}/>
      </Drawer>

    </>
  );
}
