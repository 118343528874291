import {buildTelHref} from "./build-tel-href";

export function buildSmsHref(phone = '', body = ''): string {
  if (!phone) {
    return '';
  }

  let href= buildTelHref(phone).replace(/^tel/, 'sms');
  if (body) {
    href += `?${body}`;
  }
  return href;
}
