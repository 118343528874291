import React from "react";
import './TopNavigator.module.scss';
import styles from './Footer.module.scss';
import {LanguagePicker} from "../i18n/switch/LanguagePicker";
import {LoginLogoutButton} from '../shared/LoginLogoutButton'
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";

const CURRENT_YEAR = new Date().getFullYear();

export function Footer() {
  const {t}= useTranslation();
  return (
    <footer className={styles.component}>
      <section>
        Lingo Daily &copy; {CURRENT_YEAR} | tel: <a href="tel:+48788547801">+48788547801</a>
        <div>
          <LanguagePicker/>
        </div>
      </section>

      <p>
        <Link href={t('gdpr.url')}>{t('gdpr.label')}</Link>
      </p>


      <LoginLogoutButton/>
    </footer>
  );
}
