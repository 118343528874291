// LoginLogoutButton.tsx
import React, {useState} from 'react';

import {useUserAuth} from "../firebase/auth/firebase-auth-provider";
import {Fab, Icon, IconButton} from "@mui/material";
import {useDialogContext} from "./dialog/dialog-provider";
import {useNavigate} from "react-router-dom";

export function LoginLogoutButton() {

  const [isPending, setIsPending] = useState(false);
  const {
    user,
    logOut, googleSignInWithPopup
  } = useUserAuth();

  const {showSnackbarMessage} = useDialogContext();
  const navigate = useNavigate();

  function doLogIn() {
    if (googleSignInWithPopup) {
      setIsPending(true);
      googleSignInWithPopup()
        .finally(() => setIsPending(false))
        .then((user) => {
          const firstName = user.displayName?.split(/\s+/g)[0];
          showSnackbarMessage(`Welcome, ${firstName}! You are logged in`);
          navigate('/admin');
        })
        .catch((reason) => {
          console.error(reason);
          showSnackbarMessage(<span className="error">Failed to log in!</span>)
        });
    }
  }

  function doLogOut() {
    if (logOut) {
      setIsPending(true);
      logOut()
        .finally(() => setIsPending(false))
        .then(() => {
          showSnackbarMessage('You logged out');
          navigate('/');
        })
        .catch((reason) => {
          console.error(reason);
          showSnackbarMessage(<span className="error">Failed to log out!</span>)
        });

    }
  }

  return (
    user ?
      <Fab
        className={`log-in-out ${isPending ? 'pulsating' : ''}`}
        size="small"
        style={{backgroundSize: 'contain', backgroundImage: user ? `url(${user.photoURL})` : 'none'}}
        onClick={doLogOut}/>
      :
      <IconButton
        className={`log-in-out ${isPending ? 'pulsating' : ''}`}
        onClick={doLogIn}
      >
        <Icon>key</Icon>
      </IconButton>
  );
}
