// BlueCrossStar.tsx
// import React from 'react';

import styles from './BlueCrossStar.module.scss';
import {useMemo} from "react";

interface BlueCrossStarProps {
  size?: string; // css value
}

export function BlueCrossStar({size}: BlueCrossStarProps) {

  const style = useMemo(
    () => size === undefined ? {} : {height: size, width: size}
    , [size]);

  return (
    <svg
      style={style}
      className={styles.BlueCrossStar}
      viewBox="0 0 189 189"
      width="380px"
      height="380px"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg">

      <rect x="40" y="80" width="40" height="10"/>
      <rect x="30" y="70" width="40" height="10"/>
      <rect x="20" y="60" width="40" height="10"/>
      <rect x="10" y="50" width="40" height="10"/>
      <rect x="0" y="40" width="40" height="10"/>
      <rect x="80" y="40" width="10" height="50"/>
      <rect x="70" y="30" width="10" height="40"/>
      <rect x="60" y="20" width="10" height="40"/>
      <rect x="50" y="10" width="10" height="40"/>
      <rect x="40" y="0" width="10" height="40"/>

      <rect x="100" y="40" width="10" height="50"/>
      <rect x="110" y="30" width="10" height="40"/>
      <rect x="120" y="20" width="10" height="40"/>
      <rect x="130" y="10" width="10" height="40"/>
      <rect x="140" y="0" width="10" height="40"/>
      <rect x="110" y="80" width="40" height="10"/>
      <rect x="120" y="70" width="40" height="10"/>
      <rect x="130" y="60" width="40" height="10"/>
      <rect x="140" y="50" width="40" height="10"/>
      <rect x="150" y="40" width="40" height="10"/>

      <rect x="100" y="100" width="50" height="10"/>
      <rect x="120" y="110" width="40" height="10"/>
      <rect x="130" y="120" width="40" height="10"/>
      <rect x="140" y="130" width="40" height="10"/>
      <rect x="150" y="140" width="40" height="10"/>
      <rect x="100" y="110" width="10" height="40"/>
      <rect x="110" y="120" width="10" height="40"/>
      <rect x="120" y="130" width="10" height="40"/>
      <rect x="130" y="140" width="10" height="40"/>
      <rect x="140" y="150" width="10" height="40"/>

      <rect x="40" y="100" width="50" height="10"/>
      <rect x="30" y="110" width="40" height="10"/>
      <rect x="20" y="120" width="40" height="10"/>
      <rect x="10" y="130" width="40" height="10"/>
      <rect x="0" y="140" width="40" height="10"/>
      <rect x="80" y="110" width="10" height="40"/>
      <rect x="70" y="120" width="10" height="40"/>
      <rect x="60" y="130" width="10" height="40"/>
      <rect x="50" y="140" width="10" height="40"/>
      <rect x="40" y="150" width="10" height="40"/>
    </svg>
  );
}
