import {doc, getFirestore, setDoc} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";

/** Create or update a document without merging */
export async function writeDocument<T extends {}>(documentPath: string, data: T): Promise<void> {
  const normalizedData = {...data};
    // @ts-ignore
  delete normalizedData.id;  // just in case
  const db = getFirestore(firebaseApp);
  await setDoc(doc(db, documentPath), data);
}
