import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BookingRecord} from "../../shared/models/booking-request";
import {getBooking} from "../../shared/data-services/get-booking";
import {buildTelHref} from "../../shared/functions/build-tel-href";
import {CancelBookingButton} from "./CancelBookingButton";
import styles from './Booking.module.scss';
import {useDialogContext} from "../../shared/dialog/dialog-provider";
import {buildSmsHref} from "../../shared/functions/build-sms-href";
import {useTranslation} from "react-i18next";
import {Bleed} from "../../shared/layout/Bleed";
import {Card, CardActions, CardContent, CardHeader, Container} from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import SmsIcon from "@mui/icons-material/Sms";

export function Booking() {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const {showSnackbarMessage} = useDialogContext();
  const navigate = useNavigate();
  const {bookingId} = useParams();
  const [booking, setBooking] = useState<BookingRecord>();
  const telHref = buildTelHref(booking?.phone);
  const headingClause = booking?.slot?.day ? `${booking.slot.day} ${booking.slot.hour}:00` : bookingId;
  const smsHref = buildSmsHref(booking?.phone);

  useEffect(() => {
    if (!bookingId || !showSnackbarMessage) {
      return;
    }

    setLoading(true);
    getBooking(bookingId!)
      .finally(() => setLoading(false))
      .then(setBooking)
      .catch((error) => {
        console.error(error);
        const errorMessage = (error as Error).message;
        showSnackbarMessage(<span className="error">Failed to delete the slot. {errorMessage}</span>);
      })
  }, [bookingId, showSnackbarMessage]);

  const detailsClassName = `${styles.bookingDetails} ${isLoading ? 'pulsating' : ''} ${booking ? '' : 'empty'}`;

  return (
    <div className={styles.Booking}>
      <Bleed background="teal">
        <Container maxWidth="md">
          <p className={styles.links}><Link href='/admin/bookings'>&larr; All Bookings</Link></p>
          <Card>
            <CardHeader title={`Booking ${headingClause}`}/>

            <CardContent>
              <div className={detailsClassName}>

                {booking &&
                    <>
                        <p>Booked on: {(booking.bookedOn.toDate()).toLocaleString()}</p>
                        <p>{booking.firstName} {booking.lastName} ({booking.language})</p>

                      {telHref && <p><a href={telHref}>{booking.phone}</a></p>}

                      {booking.email && <p><a href={`mailto:${booking.email}`}>{booking.email}</a></p>}

                      {
                        booking.comments &&
                          <fieldset>
                              <legend>Comments:</legend>
                              <p>{booking.comments}</p>
                          </fieldset>
                      }

                    </>}
              </div>
            </CardContent>

            {
              booking &&
                <CardActions sx={{justifyContent: 'center', gap: '1rem', background: '#f6f6f6'}}>
                    <CancelBookingButton
                        buttonClassName="primary"
                        slot={booking.slot!}
                        onSuccess={() => navigate('/admin/bookings')}
                    />

                  {smsHref &&
                      <Button
                          color="secondary"
                          href={`${smsHref}?body=${t('booking.reminder' as any, {
                            date: booking.slot?.day,
                            time: `${booking.slot?.hour}:00`,
                            lng: booking.language
                          } as any)}`}
                          startIcon={<SmsIcon/>}
                      >
                          Remind
                      </Button>
                  }

                </CardActions>
            }
          </Card>
        </Container>
      </Bleed>
    </div>)
}
