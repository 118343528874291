import {Link, useLocation} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ContentDocument} from "../models/content-document";
import {Editor} from "react-draft-wysiwyg";
import DOMPurify from 'dompurify';
import {useUserAuth} from "../../firebase/auth/firebase-auth-provider";
import {writeDocument} from "../data-services/write-document";
import {DIALOG_CHOICES, useDialogContext} from "../dialog/dialog-provider";
import {getDocumentWithId} from "../data-services/get-document-with-id";
import styles from './ContentPage.module.scss';
import {stateToHTML} from "draft-js-export-html";
import {ContentState, convertFromHTML, EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {BasicLayout} from "../layout/BasicLayout";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader, Container,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import {Call} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Bleed} from "../layout/Bleed";
import AddIcon from '@mui/icons-material/Add';

export function ContentPage() {
  const {user} = useUserAuth();
  const {pathname} = useLocation();
  const {showSnackbarMessage, askModalQuestion} = useDialogContext();
  const [document, setDocument] = useState<ContentDocument>();
  const [editorState, setEditorState] = useState<EditorState>();
  const [isEditing, setEditing] = useState(false);
  const [isReading, setReading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [newHeading, setNewHeading] = useState('');
  const [newTeaser, setNewTeaser] = useState('');
  const [newHomePosition, setNewHomePosition] = useState(0);
  const {t} = useTranslation();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('sm'));

  const cleanHtml = useMemo(() => DOMPurify.sanitize(document?.html ?? ''), [document]);

  const fetchDocument = useCallback(async () => {
    if (!pathname) {
      return;
    }
    setReading(true);
    try {
      const contentDoc = await getDocumentWithId<ContentDocument>(pathname);
      setDocument(contentDoc);
    } catch (error) {
      console.error(error);
      const errorMessage = (error as Error).message;
      showSnackbarMessage(<span className="error">Failed to fetch content. {errorMessage}</span>);
    }
    setReading(false);
  }, [pathname, showSnackbarMessage]);

  async function createContentDocument() {
    await writeDocument(pathname,
      {
        heading: pathname,
        html: `<p>Edit ${pathname}</p>`
      });
    await fetchDocument();
  }

  useEffect(() => {
    fetchDocument();
  }, [pathname, fetchDocument]);

  function startEditing() {
    if (!document) {
      return;
    }
    setNewHeading(document.heading ?? document.id);
    setNewTeaser(document.teaser ?? '');
    setNewHomePosition(document.homePosition ?? 0);
    const initialHtml = document?.html || '-- Edit the main text --';
    const contentBlock = convertFromHTML(initialHtml);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setEditing(true);
  }

  const saveContent = useCallback(async () => {
    if (!editorState || !document?.id) {
      return;
    }

    setSaving(true);
    const newDocument = {
      ...document,
      heading: newHeading,
      teaser: newTeaser,
      homePosition: newHomePosition,
      html: stateToHTML(editorState.getCurrentContent())
    };

    setDocument(newDocument);
    try {
      await writeDocument(pathname, newDocument);
      showSnackbarMessage(<>Document saved: <strong>{pathname}</strong></>)
    } catch (error) {
      console.error(error);
      const errorMessage = (error as Error).message;
      showSnackbarMessage(<span className="error">Failed to create a slot. {errorMessage}</span>);
    }
    setSaving(false);
    setEditing(false);
  }, [document, editorState, newHeading, newHomePosition, newTeaser, pathname, showSnackbarMessage]);

  const cancelEditing = useCallback(async () => {
    const newHtml = stateToHTML(editorState!.getCurrentContent());
    if (
      (newHtml !== document?.html) ||
      (newTeaser !== document?.teaser) ||
      (newHomePosition !== (document?.homePosition ?? 0))
    ) {
      if (await askModalQuestion({
        prompt: 'Cancel? Sure?',
        heading: 'You have made some changes to the page'
      }) === DIALOG_CHOICES.NO) {
        return;
      }
    }
    setEditing(false);
  }, [askModalQuestion, document?.html, editorState, document?.homePosition, document?.teaser, newHomePosition, newTeaser]);

  const editorUI = useMemo(() => (
    <Bleed background="LightSkyBlue">
      <Container disableGutters maxWidth={'md'}>
        <Card sx={{background: 'aliceblue'}}>
          <CardHeader title={`Edit the page: ${pathname}`}/>
          <CardContent>
            <p>
              <TextField
                sx={{width: '100%'}}
                label="Heading"
                value={newHeading}
                onChange={(e) => setNewHeading(e.target.value)}
              />
            </p>

            <div className="stackable">
              <div>
                <TextField
                  sx={{width: '100%'}}
                  label="Teaser"
                  multiline
                  value={newTeaser}
                  onChange={(e) => setNewTeaser(e.target.value)}
                />
              </div>

              <div>
                <TextField
                  sx={{width: '100%'}}
                  type="number"
                  label="Home position"
                  value={newHomePosition}
                  onChange={(e) => setNewHomePosition(Number(e.target.value))}
                  helperText="If not 0, the teaser will appear on the front page at this position"
                />
              </div>

            </div>

            <p>
              <Editor
                toolbarStyle={{background: '#ffffff99'}}
                editorStyle={{background: '#ffffff99'}}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
              />
            </p>
          </CardContent>

          <CardActions sx={{justifyContent: 'center'}}>
            <Button onClick={cancelEditing} variant="outlined">Cancel</Button>
            <Button
              onClick={saveContent}
              className={`primary ${isSaving ? 'pulsating' : ''}`}
              disabled={isSaving}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Bleed>

  ), [cancelEditing, editorState, isSaving, newHeading, newHomePosition, newTeaser, saveContent, pathname]);

  return (
    <div className={`${styles.component} ${pathname.replace(/\//g, '-')}`}>
      {
        isEditing
          ? editorUI
          :
          (
            <BasicLayout>
              <Card>
                {user && document &&
                    <IconButton onClick={startEditing} sx={{position: 'absolute', right: '.5rem', top: '0.5rem'}}>
                        <EditNoteIcon/>
                    </IconButton>
                }

                {
                  document
                    ? ( // Display document
                      <CardContent>
                        {document.heading && <h2>{document.heading}</h2>}
                        {Boolean(document.html) && <div dangerouslySetInnerHTML={{__html: cleanHtml}}/>}
                      </CardContent>
                    )

                    : ( // Load or create
                      <div className={isReading ? 'pulsating empty' : ''}>
                        {
                          !isReading && <>
                                <p className={styles.noDoc}>No document {pathname}</p>
                            {
                              user &&
                                <CardActions sx={{justifyContent: 'center'}}>
                                    <Button
                                        color="primary"
                                        onClick={createContentDocument}
                                        startIcon={<AddIcon/>}
                                    >Create</Button>
                                </CardActions>
                            }
                            </>
                        }
                      </div>
                    )
                }
                {isNarrow &&
                    <CardActions sx={{justifyContent: 'center', flexDirection: 'column', gap: '1rem'}}>
                        <Button color="primary" component={Link} to="/book">
                          {t('home.bookConsultation')}
                        </Button>
                        <Button variant="outlined" href="tel:+48788547801" startIcon={<Call/>}>
                          {t('home.callUs')}
                        </Button>
                    </CardActions>
                }
              </Card>
            </BasicLayout>
          )
      }
    </div>
  )
    ;
}
