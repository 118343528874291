// DrawerMenu.tsx
import React, {useMemo} from 'react';

import styles from './DrawerMenu.module.scss';
import {Box, Divider, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LanguagePicker} from "../i18n/switch/LanguagePicker";
import {LoginLogoutButton} from "../shared/LoginLogoutButton";
import {BlueCrossStar} from "../shared/imagery/BlueCrossStar";

interface DrawerMenuItem {
  iconKey: string;
  label: string;
  route: string;
}

interface DrawerMenuProps {
  canManage: boolean;
  onItemSelect: (route: string) => void;
}

export function DrawerMenu({canManage, onItemSelect}: DrawerMenuProps) {
  const {t, i18n} = useTranslation();

  const drawerMenuItems = useMemo<DrawerMenuItem[]>(() => {
    const menu = [
      {
        iconKey: 'home',
        label: t('drawerMenu.home'),
        route: '/'
      },
      {
        iconKey: 'event_available',
        label: t('booking.linkLabel'),
        route: '/book'
      },
      {
        iconKey: 'people_alt',
        label: t('about.title'),
        route: t('about.route')
      },
      {
        iconKey: 'map',
        label: t('topMenu.contact'),
        route: `/${i18n.language}/contact`
      },
    ];

    if (canManage) {
      menu.push({
        iconKey: 'edit_calendar',
        label: 'Manage',
        route: '/admin'
      });
    }

    return menu;
  }, [t, i18n, canManage]);

  return (
    <div className={styles.DrawerMenu}>
      <Box sx={{width: 250}} role="presentation">
        <List>
          {drawerMenuItems.map((menuItem =>
            <ListItem disablePadding key={menuItem.iconKey + menuItem.route}>
              <ListItemButton onClick={() => onItemSelect(menuItem.route)}>
                <ListItemIcon>
                  {
                    menuItem.route === '/'
                      ? <BlueCrossStar size="1.6rem"/>
                      : <Icon>{menuItem.iconKey}</Icon>
                  }
                </ListItemIcon>
                <ListItemText primary={menuItem.label}/>
              </ListItemButton>
            </ListItem>))}
        </List>
        <Divider/>

        <p>
          <LanguagePicker/>
        </p>

        <section className={styles.bottom}>
          <LoginLogoutButton/>
        </section>
      </Box>
    </div>
  );
}
