import {doc, getFirestore, runTransaction} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {BookingRequest} from "../models/booking-request";
import {Slot} from "../models/slot";
import {dateToId} from "../functions/date-to-id";
import {slotNameString} from "../models/slot-utils";
import {MailDocument} from "../models/mail-document";
import {objectToText} from "../functions/object-to-text";

// Create a booking for a slot and update the slot
export async function bookSlot(bookingRequest: BookingRequest) {
  if (!bookingRequest.slot?.id || !bookingRequest.slot?.hour) {
    throw new Error('No slot');
  }

  const db = getFirestore(firebaseApp);

  const slotDocRef = doc(db, 'slots', bookingRequest.slot.id)

  await runTransaction(db, async (transaction) => {
    const slotDoc = await transaction.get(slotDocRef);
    if (!slotDoc.exists()) {
      throw new Error(`Slot ${bookingRequest.slot!.id} does not exist!`);
    }

    const slot = slotDoc.data() as Slot;
    if (slot.bookingId) {
      throw new Error(`Slot ${bookingRequest.slot!.id} is already booked!`);
    }

    const bookingDocId = `booking-${bookingRequest.slot!.day}-${bookingRequest.slot!.hour}`;

    const bookingDocRef = doc(db, 'bookings', bookingDocId);

    const bookedOn = new Date();

    transaction.set(bookingDocRef, {...bookingRequest, bookedOn});
    transaction.update(slotDocRef, 'bookingId', bookingDocId);

    const emailDocId = `booking-alert-${dateToId(bookedOn)}`;

    const emailDocRef = doc(db, 'emails', emailDocId);
    const bookingRequestTxt = objectToText(bookingRequest);
    const emailDoc: MailDocument = {
      from: 'Karta Pobytu <pete@yo-site.biz>',
      to: 'Karta Pobytu <manager@lingo-daily.com>',
      message: {
        subject: `Consultation booking alert: ${slotNameString(bookingRequest.slot!)}`,
        text: bookingRequestTxt,
        html: `<h2>Consultation has been booked:</h2><pre>${bookingRequestTxt}</pre>`
      }
    };
    transaction.set(emailDocRef, emailDoc);
  });
}
