import moment, {Moment} from "moment";

const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const ISO_DATE_PATTERN = /^\d{4}-[0-1]\d-[0-3]\d$/;

export function isoFormat(date: Date|string|Moment): string {
  return moment(date).format(ISO_DATE_FORMAT);
}

export function isIsoDateStr(dateStr: string): boolean {
  return ISO_DATE_PATTERN.test(dateStr);
}
