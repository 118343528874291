import React, {useEffect} from 'react';
import './App.scss';
import {BrowserRouter} from "react-router-dom";
import {UserAuthContextProvider} from "./firebase/auth/firebase-auth-provider";
import {TopNavigator} from "./navigators/TopNavigator";
import {Routing} from "./Routing";
import {Footer} from "./navigators/Footer";
import {DialogProvider} from "./shared/dialog/dialog-provider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/uk';
import useLocalStorage from "./shared/data-services/use-local-storage"; // Ukrainian
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import {kpTheme} from "./styles/themes/kp-theme";
import {STORAGE_KEY_LANG} from "./i18n/i18n-init";
import {CookiesDisclaimer} from "./cookies/CookiesDisclaimer";

/**
 * See https://mui.com/material-ui/experimental-api/css-theme-variables/usage/
 *
 */
const theme = extendTheme(kpTheme);

function App() {
  const {i18n} = useTranslation();
  const [language] = useLocalStorage(STORAGE_KEY_LANG, 'en');

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    i18n.changeLanguage(language);
  }, [i18n, language]); // language can be changed in localStorage by a different tab

  return (
    <UserAuthContextProvider>
      <CssVarsProvider theme={theme}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
            <DialogProvider>
              <TopNavigator/>
              <main>
                <Routing/>
              </main>
              <Footer/>
            </DialogProvider>
          </LocalizationProvider>
        </BrowserRouter>

        <CookiesDisclaimer/>
      </CssVarsProvider>
    </UserAuthContextProvider>
  );
}

export default App;
