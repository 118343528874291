import {useDialogContext} from "../../shared/dialog/dialog-provider";
import React, {useCallback, useMemo, useState} from "react";
import styles from './AddSlot.module.scss';
import {createSlot} from "../../shared/data-services/create-slot";
import {POSSIBLE_SLOTS} from "../../shared/models/hours";
import {Card, CardHeader, IconButton, Tooltip} from "@mui/material";
import moment from "moment/moment";
import {isoFormat} from "../../shared/models/iso-date-format";
import {DatePicker} from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import {Moment} from "moment";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export function AddSlot({onAddSlot}: { onAddSlot: () => void }) {
    const {showSnackbarMessage} = useDialogContext();
    const [day, setDay] = useState(new Date().toISOString().substring(0, 10));
    const [hoursBeingAdded, setHoursBeingAdded] = useState<number[]>([]);

    const canDecrementDay = useMemo(() => {
        return day > isoFormat(new Date());
    }, [day]);

    const incrementDay = useCallback(() => {
        setDay(isoFormat(moment(day).add(1, 'day') as Moment));
    }, [setDay, day]);

    const decrementDay = useCallback(() => {
        setDay(isoFormat(moment(day).add(-1, 'day') as Moment));
    }, [setDay, day]);

    async function addSlot(hour: number) {
        function doneWithHour() {
            setHoursBeingAdded(hoursBeingAdded =>
                hoursBeingAdded.filter(h => h !== hour));
        }

        setHoursBeingAdded([...hoursBeingAdded, hour]);
        try {
            await createSlot(day, hour);
            onAddSlot();
            showSnackbarMessage(`Created a free slot on add ${day} at ${hour}:00`);
        } catch (error) {
            console.error(error);
            const errorMessage = (error as Error).message;
            showSnackbarMessage(<span className="error">Failed to create a slot. {errorMessage}</span>);
            doneWithHour();
        } finally {
            doneWithHour();
        }
    }

    return <div className={styles.component}>
        <Card>
            <CardHeader title="New consulting day"/>
            <p>
                <Tooltip title="Previous day">
                    <IconButton
                        disabled={!canDecrementDay}
                        onClick={decrementDay}
                    >
                        <ArrowBackIosIcon/>
                    </IconButton>
                </Tooltip>

                <DatePicker
                    sx={{width: '10rem', marginLeft: '0.5rem', marginRight: '0.5rem'}}
                    value={moment(day)}
                    name="date"
                    label="Day"
                    onChange={
                        (newMoment: Moment | null) => {
                            if (newMoment) {
                                setDay(newMoment.isValid() ? isoFormat(newMoment) : '');
                            }
                        }}
                    disablePast
                />

                <Tooltip title="Next day">
                    <IconButton onClick={incrementDay}><ArrowForwardIosIcon/></IconButton>
                </Tooltip>
            </p>

            <p>
                {POSSIBLE_SLOTS.map((slotHour) =>
                    <Button
                        key={slotHour}
                        variant="text"
                        size="small"
                        disabled={hoursBeingAdded.includes(slotHour)}
                        className={hoursBeingAdded.includes(slotHour) ? 'pulsating' : ''}
                        onClick={() => addSlot(slotHour)}>{slotHour}<sup>:00</sup></Button>)}
            </p>
        </Card>
    </div>
}
