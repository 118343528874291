// Bleed.tsx
import React, {ReactNode} from 'react';

import styles from './Bleed.module.scss';

interface BleedProps {
  children?: ReactNode | ReactNode[];
  style?: React.CSSProperties;
  background?: string;
}

export function Bleed({children, style, background}: BleedProps) {
  return <div
    className={styles.component}
    style={{
      background: background ?? 'transparent',
      ...(style ?? {})
    }}
  >
    {children}
  </div>;
}
