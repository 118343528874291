import {Link} from "react-router-dom";

export function AdminConsole() {
  return <div className="AdminConsole" style={{width: 'max-content', margin: '2rem auto'}}>
    <h1>Admin Console</h1>
    <menu style={{listStyle: 'none', padding: 0}}>
      <li><Link className={'button'} to="/admin/bookings">Bookings</Link></li>
      <li>---</li>
      <li><Link className={'button'} to="/admin/days">Consulting Days</Link></li>
    </menu>
    </div>
}
