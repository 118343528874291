// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCuZFquuOO8wHZMN3a_IElTGFcp79N0BaA",
  authDomain: "karta-pobytu-a2d90.firebaseapp.com",
  projectId: "karta-pobytu-a2d90",
  storageBucket: "karta-pobytu-a2d90.appspot.com",
  messagingSenderId: "97169038511",
  appId: "1:97169038511:web:713de0699425d27497c85a",
  measurementId: "G-MELLH31XYY"
};
