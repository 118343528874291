import {getAuth, Auth} from "firebase/auth";
import {firebaseApp} from "../init-firebase-app";

let auth: Auth;

export function getAuthInLanguage() {
  if (!auth) {
    auth = getAuth(firebaseApp);
    auth.useDeviceLanguage();
  }
  return auth;
}
