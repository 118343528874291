import {Slot} from "../../shared/models/slot";
import React, {useMemo} from "react";
import {slotsToPotential} from "../../shared/models/slot-utils";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {Tooltip} from "@mui/material";

interface ConsultingDaySlotsProps {
    slots: Slot[];
    slotsBeingChanged: Slot[];
    day: string;
    onAddSlot: (slot: Slot) => void;
    onDeleteSlot: (slot: Slot) => void;
}

export function ConsultingDaySlots({slots, slotsBeingChanged, day, onDeleteSlot, onAddSlot}: ConsultingDaySlotsProps) {
    const allSLots: Slot[] = useMemo<Slot[]>(() => slotsToPotential(slots, day), [slots, day]);

    return <div className={'slot-buttons'}>
        {
            allSLots.map((slot) =>
                slot.bookingId
                    ?
                    <Tooltip title="Booked">
                        <Link
                            key={slot.id}
                            sx={{
                                border: '1px solid currentColor',
                                borderRadius: '0.2em',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            color="primary"
                            underline="hover"
                            href={`/admin/bookings/${slot.bookingId}`}
                        >{slot.hour}<sup>:00</sup></Link>
                    </Tooltip>

                    :
                    <Tooltip title={slot.potential ? 'Out of service' : 'Available to book'}>
                        <Button
                            key={slot.id}
                            color={slot.potential ? 'secondary' : 'primary'}
                            size="small"
                            className={`${slotsBeingChanged.includes(slot) ? 'pulsating' : ''}`}
                            onClick={() => slot.potential ? onAddSlot(slot) : onDeleteSlot(slot)}
                        >
                            {slot.hour}<sup>:00</sup>
                        </Button>
                    </Tooltip>
            )
        }
    </div>
}
