import {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {ModalQuestion, ModalQuestionProps} from "./ModalQuestion";
import Snackbars from "./Snackbars";

export enum DIALOG_CHOICES {
  NO = 0,
  YES = 1,
}

type ExportModalQuestionProps = Omit<ModalQuestionProps, 'emitChoice'>;

interface DialogService {
  askModalQuestion: (question: ExportModalQuestionProps) => Promise<number>;
  showSnackbarMessage: (message: ReactNode) => void;
}

const DialogContext = createContext<DialogService>({
    askModalQuestion: () => Promise.reject(),
    showSnackbarMessage: () => undefined,
  }
);

export function DialogProvider({children}: { children: ReactNode | ReactNode[] }) {
  const [modalQuestion, setModalQuestion] = useState<ModalQuestionProps | null>(null);
  const [snackbarMessages, setSnackbarMessages] = useState<ReactNode[]>([]);

  const askModalQuestion = useCallback((question: ExportModalQuestionProps) => {
    return new Promise<number>((resolve) => {
      setModalQuestion({
        ...question,
        emitChoice: (n) => {
          resolve(n);
          setModalQuestion(null);
        }
      });
    });
  }, []);

  function removeSnackbarMessage(message: ReactNode) {
    setSnackbarMessages(snackbarMessages =>
      snackbarMessages.filter(m => m !== message));
  }

  const showSnackbarMessage = useCallback((message: ReactNode) => {
    setSnackbarMessages(snackbarMessages => {
      const stringifiedMessage = JSON.stringify(message);
      if (snackbarMessages.some((sm) => JSON.stringify(sm) === stringifiedMessage)) {
        return snackbarMessages;
      }
      return [...snackbarMessages, message];
    });
    setTimeout(() => removeSnackbarMessage(message), 5000);
  }, []);

  return (
    <DialogContext.Provider value={{askModalQuestion, showSnackbarMessage}}>

      {
        modalQuestion && <ModalQuestion
              prompt={modalQuestion.prompt}
              choices={modalQuestion.choices}
              heading={modalQuestion.heading}
              emitChoice={modalQuestion.emitChoice}
          />
      }
      {children}
      <Snackbars messages={snackbarMessages}/>
    </DialogContext.Provider>
  );
}

export function useDialogContext() {
  return useContext(DialogContext);
}
