// BasicLayout.tsx
import React, {ReactNode, useState} from 'react';

import styles from './BasicLayout.module.scss';
import {Bleed} from "./Bleed";
import {CardContent, Container, useMediaQuery, useTheme} from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Call} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const DEFAULT_IMAGE_URL = '/static/images/blond-consultant-1000_F_630134862_xeZIZimLXx2VYUrJrqJdR4Zf2hAvDqJw.jpg'

interface BasicLayoutProps {
  hasCtasOnMobile?: boolean;
  children?: ReactNode | ReactNode[];
}

export function BasicLayout({children, hasCtasOnMobile = false}: BasicLayoutProps) {
  const [imageUrl] = useState(DEFAULT_IMAGE_URL);
  const {t} = useTranslation();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));

  return (<div className={styles.BasicLayout}>
    <Bleed background="lightsteelblue">
      <Container maxWidth="lg">
        <img width="800" alt="" src={imageUrl}/>

        {
          (isWide || hasCtasOnMobile) &&
            <section>
                <Paper>
                    <CardContent>
                        <p>
                            <Button color="primary" component={Link} to="/book">
                              {t('home.bookConsultation')}
                            </Button>
                        </p>

                        <p>
                            <Button variant="outlined" href="tel:+48788547801" startIcon={<Call/>}>
                              {t('home.callUs')}
                            </Button>
                        </p>
                    </CardContent>
                </Paper>
            </section>
        }

        <div className={styles.children}>
          {children}
        </div>
      </Container>
    </Bleed>

  </div>);
}
