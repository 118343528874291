import {BookingRequest} from "./booking-request";
import {isValidEmail} from "../functions/is-valid-email";
import {isValidPhone} from "../functions/is-valid-phone";

export function isBookingRequestValid(bookingRequest: BookingRequest): boolean {
  return Boolean(bookingRequest.firstName)
    && Boolean(bookingRequest.lastName)
    && (!bookingRequest.email || isValidEmail(bookingRequest.email))
    && (!bookingRequest.phone || isValidPhone(bookingRequest.phone))
    && (Boolean(bookingRequest.email) || Boolean(bookingRequest.phone))
    && Boolean(bookingRequest.language)
    && Boolean(bookingRequest.slot?.hour);
}
