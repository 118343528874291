import {BookingRecord} from "../../shared/models/booking-request";
import {Link} from "react-router-dom";
import {buildTelHref} from "../../shared/functions/build-tel-href";
import {buildSmsHref} from "../../shared/functions/build-sms-href";
import {useTranslation} from "react-i18next";
import {Card, CardActions, CardContent, CardHeader} from "@mui/material";
import Button from "@mui/material/Button";
import SmsIcon from '@mui/icons-material/Sms';

interface BookingCardProps {
  booking: BookingRecord
}

export function BookingCard({booking}: BookingCardProps) {
  const {t} = useTranslation();
  const telHref = buildTelHref(booking.phone);
  const smsHref = buildSmsHref(booking.phone);

  return <Card data-kind="BookingCard">
    <CardHeader
      sx={{background: 'BlanchedAlmond'}}
      title={
        <Link to={booking.id!}>{booking.slot!.day} {booking.slot!.hour}:00 </Link>
      }
    />
    <CardContent>
      <p>
        Booked on: {(booking.bookedOn.toDate()).toLocaleString()}
      </p>
      <p>{booking.firstName} {booking.lastName} ({booking.language})</p>
      {telHref && <p><a href={telHref}>{booking.phone}</a></p>}
      {booking.email && <p><a href={`mailto:${booking.email}`}>{booking.email}</a></p>}

    </CardContent>
    {smsHref && <CardActions sx={{justifyContent: 'center', background: '#f6f6f6'}}>
        <Button
            color="secondary"
            href={`${smsHref}?body=${t('booking.reminder' as any, {
              date: booking.slot!.day,
              time: `${booking.slot!.hour}:00`,
              lng: booking.language
            } as any)}`}
            startIcon={<SmsIcon/>}
        >
            remind
        </Button>
    </CardActions>}
  </Card>
}
