import i18n, {ResourceLanguage} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './en/translation.json';
import translationPl from './pl/translation.json';
import translationRu from './ru/translation.json';
import translationUa from './ua/translation.json';
import {IS_LOCAL} from "../shared/is-local";
import {HomeTile} from "../shared/models/home-tiles/home-tile";

interface MpTranslationResource extends ResourceLanguage {
  translation: {
    home: {
      serviceCollection: string,
      tiles: HomeTile[]
    }
  }
}

export const languageResources: { [lang: string]: MpTranslationResource } = {
  en: {translation: translationEn},
  pl: {translation: translationPl},
  ru: {translation: translationRu},
  ua: {translation: translationUa},
};

export const STORAGE_KEY_LANG = 'mp-lang';
const savedLanguage = window?.localStorage.getItem(STORAGE_KEY_LANG);
const initialLanguage = savedLanguage ? JSON.parse(savedLanguage) : 'en';

// noinspection JSIgnoredPromiseFromCall
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: IS_LOCAL,
    resources: languageResources,
    fallbackLng: 'en',
    lng: initialLanguage,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export const SITE_LANGUAGES = Object.keys(languageResources).sort();
