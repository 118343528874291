import {Slot} from "./slot";
import React, {ReactNode} from "react";
import {ConsultingDay} from "./consulting-day";
import {POSSIBLE_SLOTS} from "./hours";

export function daysOfUnbookedSlots(slots: Slot[]): Set<string> {
  if (!slots?.length) {
    return new Set<string>();
  }

  return new Set(slots
    .filter((slot) => !slot.bookingId)
    .map((slot) => slot.day));
}

export function unbookedSlots(slots: Slot[], day: string): Slot[] {
  return slots
    .filter(slot => slot.day === day && !slot.bookingId)
    .sort((slotA, slotB) => slotA.hour - slotB.hour);
}

export function slotName(slot: Slot): ReactNode {
  return <>
    {slot.day}&nbsp;{slot.hour < 10 ? <>&nbsp;</> : null}
    {slot.hour}:00
  </>;
}

export function slotNameString(slot: Slot): string {
  return `${(new Date(slot.day)).toLocaleDateString()} ${slot.hour}:00`;
}

export function slotsToConsultingDays(slots: Slot[]): ConsultingDay[] {
  const dayHash = slots.reduce((acc, cur) => {
    acc[cur.day] = acc[cur.day] ? [...acc[cur.day], cur] : [cur];
    return acc;
  }, {} as { [key: string]: Slot[] });

  return Object.entries(dayHash)
    .map(([day, daySlots]) => ({day, slots: daySlots}))
    .sort((d1, d2) =>
      (d1.day === d2.day) ? 0 : (d1.day > d2.day ? 1 : -1));
}

export function slotsToPotential(slots: Slot[], day: string): Slot[] {
  return POSSIBLE_SLOTS.map((possibleHour) =>
    slots.find((slot) => slot.hour === possibleHour) ?? {
      id: `potential-${day}-${possibleHour}`,
      hour: possibleHour,
      day,
      potential: true
    });
}
