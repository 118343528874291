import {collection, getDocs, getFirestore, orderBy} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {query, where} from "@firebase/firestore";
import {ContentDocument} from "../models/content-document";

// Get a list of services assigned to the home page ordered by their homePosition
export async function getHomeTiles(collectionPath: string) {
  const db = getFirestore(firebaseApp);
  const homeTilesCol = collection(db, collectionPath);
  const homeTilesSnapshot = await getDocs(
    query(
      homeTilesCol,
      where('homePosition', '>', 0),
      orderBy('homePosition')
    )
  );

  return homeTilesSnapshot.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  })) as ContentDocument[];
}
