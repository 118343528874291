import React, {useEffect, useState} from "react";
import {BookingRecord} from "../../shared/models/booking-request";
import {getBookings} from "../../shared/data-services/get-bookings";
import {BookingCard} from "./BookingCard";
import {Link} from "react-router-dom";
import styles from './BookingList.module.scss'

let persistedBookings: BookingRecord[] | null = null;

export function BookingList() {
  const [bookings, setBookings] = useState<BookingRecord[] | null>(persistedBookings);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');

  const fetchBookings = () => {
    setPending(true);
    getBookings()
      .then((upcomingBookings) => {
        persistedBookings = upcomingBookings;
        setBookings(upcomingBookings);
        setPending(false);
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
        setError('Error fetching bookings: ' + JSON.stringify(error))
        setPending(false);
      })
  };

  useEffect(() => fetchBookings(), []);

  let divClassName = `${pending ? 'pulsating' : ''} ${bookings ? '' : 'empty'}`

  return (
    <article className={styles.component}>
      <h2>Bookings</h2>
      <p>See also: <Link to='/admin/days'>Consulting Days</Link></p>

      {error && <p className="error">{error}</p>}

      <div className={divClassName}>
        {bookings && (bookings.length > 0
          ? bookings.map((aBooking) =>
            <BookingCard booking={aBooking} key={Number(aBooking.bookedOn)}/>)
          : <p>No slots booked currently. Best of luck!</p>)
        }
      </div>
    </article>
  );
}
