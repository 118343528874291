import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Slot} from "../shared/models/slot";
import {getSlots} from "../shared/data-services/get-slots";
import {daysOfUnbookedSlots, slotNameString, unbookedSlots} from "../shared/models/slot-utils";
import {BookingRequest} from "../shared/models/booking-request";
import {bookSlot} from "../shared/data-services/book-slot";
import {isBookingRequestValid} from "../shared/models/is-booking-request-valid";
import styles from './BookingForm.module.scss';
import {useDialogContext} from "../shared/dialog/dialog-provider";
import {Trans, useTranslation} from "react-i18next";
import {IS_LOCAL} from "../shared/is-local";
import {DatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import {isoFormat} from "../shared/models/iso-date-format";
import {Fab, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField,} from "@mui/material";
import {objectToText} from "../shared/functions/object-to-text";
import {PayloadStates} from "../shared/models/payload-states";
import {bookingStateToButtonText} from "../shared/models/booking-state-to-button-text";
import Button from "@mui/material/Button";
import {SubmitHandler, useForm} from "react-hook-form";
import {isEmptyOrValidEmail} from "../shared/functions/is-valid-email";
import {isEmptyOrValidPhone} from "../shared/functions/is-valid-phone";
import {DaySlots} from "./DaySlots";

let initialFormData: BookingRequest = {
  firstName: '',
  lastName: '',
  language: 'pl',
  slot: undefined,
  comments: '',
  email: '',
  phone: '',
};

interface Language {
  code: string;
  name: string;
}

const LANGUAGES: Language[] = [
  {code: "pl", name: "Polish"},
  {code: "ru", name: "Russian"},
  {code: 'en', name: "English"},
  // {code: "ua", name: "Ukrainian"},
];

let wasDebugShown = IS_LOCAL;

let renderCount = 0;

export default function BookingForm() {
  const {t} = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [bookingPayloadState, setBookingPayloadState] = useState<PayloadStates>(PayloadStates.UNSET);
  const {showSnackbarMessage} = useDialogContext();
  const [shouldShowDebug, setShowDebug] = useState(wasDebugShown);
  const {
    formState,
    formState: {isValid},
    formState: {isDirty},
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    unregister,
    watch,
  } = useForm<BookingRequest>({
      defaultValues: initialFormData,
      mode: 'all', // Validate form on each input change
      criteriaMode: "all", // Check all validation criteria
      reValidateMode: 'onChange', // Re-validate on every change
    }
  );

  const watchAllFields = watch();

  const handleDebugSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDebug(event.target.checked);
    wasDebugShown = event.target.checked;
  }

  const days = useMemo(() => daysOfUnbookedSlots(slots), [slots]);

  const loadSlots = useCallback(async () => {
    setLoading(true);

    try {
      const newSlots = await getSlots();
      setSlots(newSlots);
    } catch (err) {
      console.error(err);
      showSnackbarMessage(<span
        className="error">{(err as { message?: string })?.message ?? t('slots.errors.errorGettingSlots')}</span>);
    }

    setLoading(false);
  }, [t, setSlots, showSnackbarMessage]);

  /** When the component mounts */
  useEffect(() => {
    loadSlots();
  }, [loadSlots]);

  const isDateUnset = !selectedDate;

  const daySlots = useMemo(() => unbookedSlots(slots, selectedDate || ''), [slots, selectedDate]);

  const doBook: SubmitHandler<BookingRequest> = async (bookingRequest) => {
    if (!isBookingRequestValid(bookingRequest)) {
      return;
    }

    setBookingPayloadState(PayloadStates.SAVING);
    try {
      await bookSlot(bookingRequest);
      setBookingPayloadState(PayloadStates.SAVED);
      reset(watchAllFields);
      showSnackbarMessage(<span className='success'>
          <Trans i18nKey="booking.consultationBooked"
                 components={{
                   strong: <strong/>
                 }}
                 values={{date: slotNameString(bookingRequest.slot!)}}/>
          </span>);
    } catch (err: unknown) {
      setBookingPayloadState(PayloadStates.FAILED);
      console.error(err);
      showSnackbarMessage(<span
        className="error">{(err as { message?: string })?.message ?? t('booking..bookingFailed')}</span>);
      await loadSlots();
    }
  };

  /** Reset selected date when no slots are available */
  useEffect(() => {
      if (isDateUnset || days.size === 0) {
        return;
      }

      const canKeepDate = days.has(selectedDate);

      if (!canKeepDate) {
        setSelectedDate('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [days]); // only when days change, not selectedDate!

  /** Reset selected hour when not available on selected day */
  useEffect(() => {
    if (isDateUnset || (watchAllFields.slot?.day === selectedDate)) {
      return;
    }

    const fittingSlot = slots.find((slot) =>
      slot.day === selectedDate
      && slot.hour === watchAllFields.slot?.hour
      && !slot.bookingId
    );

    setValue('slot', fittingSlot);
  }, [setValue, slots, isDateUnset, selectedDate, watchAllFields.slot]);

  const needToLoadSlots = bookingPayloadState === PayloadStates.SAVED && isDirty;

  /** React to form changes: validate and reload slots */
  /** When a booking was attempted, and the user changes the form, reset the payload status */
  useEffect(() => {
    if (needToLoadSlots) {
      setBookingPayloadState(PayloadStates.UNSET);
      setValue('slot', undefined);
      loadSlots();
    }
  }, [needToLoadSlots, loadSlots, setValue]);

  const buttonText = t(bookingStateToButtonText(bookingPayloadState));

  const atLeastEmailOrPhone = () => {
    return Boolean(watchAllFields.email) || Boolean(watchAllFields.phone) ||
      t('booking.form.errors.emailOrPhoneRequired');
  }

  useEffect(() => {
    register('slot', {required: true});

    return () =>
      unregister('slot');
  }, [register, unregister]);

  const handleAutofill = useCallback((e: AnimationEvent) => {
    if (e.animationName === 'autofill') {
      // Manually trigger validation for the autofilled fields
      trigger('email');
      trigger('phone');
    }
  }, [trigger]);

  useEffect(() => {
    const inputs = document.querySelectorAll<HTMLInputElement>(
      `.${styles.component} input[name="email"], .${styles.component} input[name="phone"]`
    );
    inputs.forEach(input => {
      input.addEventListener('animationstart', handleAutofill, false);
    });

    return () =>
      inputs.forEach(input =>
        input.removeEventListener('animationstart', handleAutofill, false));
  }, [trigger, handleAutofill]);

  const datePickerErrorTextKey = useMemo(() => {
    if (selectedDate === '') {
      return 'booking.form.errors.dateRequired';
    }
    if (selectedDate && !days.has(selectedDate)) {
      return 'booking.form.errors.dateUnavailable';
    }
    return false
  }, [selectedDate, days]);

  return (
    <div className={styles.component}>
      <h3>{t('booking.heading' as any)}</h3>

      {IS_LOCAL && <Fab style={{position: "fixed", bottom: '1rem', left: '1rem'}}>{renderCount++}</Fab>}

      <form
        onSubmit={handleSubmit(doBook)}
        className={bookingPayloadState}
      >
        <div className={'stackable ' + styles.stackable}>
          <div>
            <FormControl>
              <TextField
                {...register("firstName", {required: 'Name is required'})}
                label={t('booking.form.firstName')}
                required
                error={Boolean(formState.errors.firstName)}
                helperText={formState.errors.firstName?.message || ''}
              />
            </FormControl>

            <FormControl>
              <TextField
                label={t('booking.form.lastName')}
                {...register("lastName", {required: 'Last name is required'})}
                required
                error={Boolean(formState.errors.lastName)}
                helperText={formState.errors.lastName?.message || ''}
              />
            </FormControl>

            <FormControl>
              <FormLabel id="language-radio-buttons-group-label">{t('booking.form.language' as any)}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="language-radio-buttons-group-label"
              >
                {
                  LANGUAGES.map((lang) =>
                    <FormControlLabel
                      checked={watchAllFields.language === lang.code}
                      {...register("language")}
                      value={lang.code}
                      control={<Radio/>}
                      label={lang.code}
                      key={lang.code}
                    />)
                }
              </RadioGroup>
            </FormControl>

            <FormControl>
              <TextField
                label="Email"
                type="email"
                {...register('email', {
                  validate: {
                    isValid: value => isEmptyOrValidEmail(value) || t('booking.form.errors.emailInvalid'),
                    atLeastOne: atLeastEmailOrPhone
                  }
                })}
                error={Boolean(formState.errors.email)}
                helperText={formState.errors.email?.message || ''}
              />
            </FormControl>

            <FormControl>
              <TextField
                label={t('booking.form.phone')}
                type="tel"
                {...register('phone', {
                  validate: {
                    isValid: value => isEmptyOrValidPhone(value) || t('booking.form.errors.phoneInvalid'),
                    atLeastOne: atLeastEmailOrPhone
                  }
                })}
                error={Boolean(formState.errors.phone)}
                helperText={formState.errors.phone?.message || ''}
              />
            </FormControl>

            {
              isLoading
                ?
                <p className="pulsating rounded-padded">{t('booking.form.loadingSlots' as any)}</p>
                : // slots loaded
                <>
                  <DatePicker
                    defaultValue={selectedDate ? moment(selectedDate) : null}
                    name="date"
                    label={t('booking.form.date' as any)}
                    onChange={
                      (newMoment) => {
                        if (newMoment) {
                          setSelectedDate(newMoment.isValid() ? isoFormat(newMoment) : '');
                        }
                      }}
                    disablePast
                    shouldDisableDate={(aDate: Moment) => !days.has(isoFormat(aDate))}
                    slotProps={{
                      textField: {
                        error: Boolean(datePickerErrorTextKey),
                        helperText: t(datePickerErrorTextKey || ''),
                      },
                    }}
                  />

                  {
                    selectedDate && daySlots.length > 0 &&
                      <div>
                          <label className={watchAllFields.slot ? '' : 'error'}>
                            {t((watchAllFields.slot ? 'booking.form.hour' : 'booking.form.errors.slotRequired') as any)}*
                          </label>
                          <DaySlots
                              slots={daySlots}
                              selectedHour={watchAllFields.slot?.hour}
                              onSelect={(slot) => setValue('slot', slot, {shouldValidate: true, shouldDirty: true})}
                          />
                      </div>
                  }
                </>
            }

            <FormControl>
              <TextField
                label={t('booking.form.comments')}
                type="text"
                multiline
                minRows={2}
                {...register('comments')}
              />
            </FormControl>

          </div>
        </div>

        <p className="buttons">
          <Button
            type="submit"
            className={` ${bookingPayloadState === PayloadStates.SAVING ? 'pulsating' : ''}`}
            disabled={!isValid || !isDirty}
          >
            {buttonText}
          </Button>

          {IS_LOCAL &&
              <FormControlLabel
                  control={<Switch checked={shouldShowDebug} onChange={handleDebugSwitch}/>}
                  label="debug info"
              />
          }

        </p>

        {
          bookingPayloadState === PayloadStates.SAVED && watchAllFields.slot &&
            <p className={styles.confirmation}>
                <Trans i18nKey="booking.consultationBooked"
                       components={{
                         strong: <strong/>
                       }}
                       values={{date: slotNameString(watchAllFields.slot)}}/>
            </p>
        }
      </form>


      {
        shouldShowDebug &&
          <pre>
          {objectToText(watchAllFields)}
              <br/>
            <br/>
          Date: {selectedDate}
              <br/>
        </pre>
      }
    </div>
  );
}
