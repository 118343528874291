import {ReactNode} from "react";
import './ModalQuestion.css';
import {ModalScreen} from "./ModalScreen";

export interface ModalQuestionProps {
  heading?: ReactNode;
  prompt: ReactNode;
  choices?: ReactNode[];
  emitChoice: (choiceIndex: number) => void;
}

export function ModalQuestion({prompt, heading, choices = ['No', 'Yes'], emitChoice}: ModalQuestionProps) {
  return <ModalScreen>
    <div className="plaque">
      {heading && <h3>{heading}</h3>}
      <section className="prompt">
        {prompt}
      </section>
      <menu>
        {
          choices.map((choice, choiceIndex) => <li key={choiceIndex}>
            <button onClick={() => emitChoice(choiceIndex)}>
              {choice}
            </button>
          </li>)
        }
      </menu>
    </div>
  </ModalScreen>
}
