import {doc, getDoc, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "../../firebase/init-firebase-app";
import {ObjectWithId} from "../models/object-with-id";

/** Get a document */
export async function getDocumentWithId<T extends ObjectWithId>(documentPath: string): Promise<T|undefined> {
  const db = getFirestore(firebaseApp);
  const aDoc = await getDoc(doc(db, documentPath));
  const docData = aDoc.data();

  return docData && {
    ...docData,
    id: aDoc.id
  } as T;
}
